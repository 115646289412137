export const companyStatusColors = {
  cs_verification_required: 'warning',
  modification_requested: 'warning',
  blacklisted: 'danger',
  approved: 'success',
  rejected: 'danger',
  price_list_requested: 'warning',
  in_review: 'info',
  draft: 'info'
};
