<template>
  <table-style-wrapper>
    <table-wrapper class="table-responsive">
      <a-table
        :columns="columns"
        :data-source="dataSource ? dataSource.data : []"
        :rowKey="(record) => record._jv.id"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >
        <template #registrationDate="{record}">
          {{ record.createdAt ? moment(record.createdAt).format('DD MMM YYYY') : '--' }}
        </template>
        <template #lastUpdateDate="{record}">
          {{ record.updatedAt ? moment(record.updatedAt).format('DD MMM YYYY') : '--' }}
        </template>
        <template #status="{record}">
          <span :class="`status-text ${companyStatusColors[record.status]}`">
            {{ $t(`models.company.status.${record.status}`) }}
          </span>
        </template>
        <template #blackListReason="{record}">
          <span v-if="!$_.isEmpty(record.blacklistComment)">
            {{ record.blacklistComment }}
          </span>
        </template>
        <template #numberOfServiceCountries="{record}" v-if="companyType != 'Client'">
          {{ record.countriesCoverage?.length }}
        </template>
        <template #action="{record, index}">
          <div class="table-actions">
            <router-link :to="companyProfile(record)">
              <sd-button class="btn-icon" type="link" shape="circle" :tooltip="$t('actions.view')">
                <sd-feather-icons type="eye" :size="16" />
              </sd-button>
            </router-link>
            <pop-over
              v-if="record.status !== 'blacklisted' && $can('blacklist', record)"
              :requiredInput="true"
              :title="$t('views.confirm.blacklistCompany.title')"
              :displayedMessage="$t('views.confirm.blacklistCompany.message')"
              @confirm="blacklist({ id: record._jv.id, index: index, event: $event })"
            >
              <sd-button class="btn-icon" type="default" shape="circle" :tooltip="$t('actions.blacklist')">
                <sd-feather-icons type="lock" size="16" />
              </sd-button>
            </pop-over>
            <pop-over
              v-if="record.status === 'blacklisted' && $can('reject', record)"
              :requiredInput="false"
              :canInput="false"
              :title="$t('views.confirm.rejectCompany.title')"
              :displayedMessage="$t('views.confirm.rejectCompany.message')"
              @confirm="reject({ id: record._jv.id, index: index })"
            >
              <sd-button class="btn-icon" type="default" shape="circle" :tooltip="$t('actions.reject')">
                <sd-feather-icons type="x-circle" size="16" />
              </sd-button>
            </pop-over>
            <router-link
              v-if="$can('verify', record)"
              @click="startInReview(record._jv.id)"
              :to="{ name: 'BackOfficeProfileVerification', params: { companyId: record._jv.id } }"
            >
              <sd-button
                class="btn-icon"
                type="link"
                shape="circle"
                :tooltip="$t('views.profileVerification.verificationProcess')"
              >
                <sd-feather-icons type="check-circle" :size="16" />
              </sd-button>
            </router-link>
          </div>
        </template>
      </a-table>
    </table-wrapper>
  </table-style-wrapper>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {usePagination} from 'vue-request';
import {useStore} from 'vuex';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {companyStatusColors} from '@/helpers/companyStatusColors';
import {companyServiceTypesColors} from '@/helpers/companyServiceTypesColors';
import moment from 'moment';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import _ from 'lodash';
import PopOver from '@/components/modals/PopOver';
import {useRoute} from 'vue-router';

export default defineComponent({
  name: 'CompaniesTable',
  props: {
    country: VueTypes.string.def(''),
    status: VueTypes.string.def(''),
    search: VueTypes.string.def(''),
    productCategoryIds: VueTypes.string.def(''),
    countriesCoverage: VueTypes.string.def('')
  },
  components: {
    TableStyleWrapper,
    TableWrapper,
    PopOver
  },
  setup(props, context) {
    const {getters, dispatch} = useStore();
    const {t} = useI18n();

    const columns = computed(() => {
      return [
        {
          title: t('models.company.attributes.registrationDate'),
          slots: {customRender: 'registrationDate'}
        },
        {
          title: t('models.company.attributes.lastUpdateDate'),
          slots: {customRender: 'lastUpdateDate'}
        },
        {
          title: t('models.company.attributes.name'),
          dataIndex: 'name'
        },
        {
          title: t('models.company.attributes.status'),
          dataIndex: 'status',
          slots: {customRender: 'status'}
        },
        {
          title: t('models.company.attributes.blackListReason'),
          slots: {customRender: 'blackListReason'}
        },
        {
          title: '',
          key: 'action',
          slots: {customRender: 'action'}
        }
      ];
    });
    const route = useRoute();
    const companyType = computed(() => (route.name === 'BackOfficeClientCompanies' ? 'Client' : 'Agent'));
    if (companyType.value === 'Agent') {
      columns.value.splice(3, 0, {
        title: t('models.company.attributes.numberOfServiceCountries'),
        slots: {customRender: 'numberOfServiceCountries'}
      });
    }

    const getCompanies = (params) => {
      params.type = companyType.value;
      return dispatch('getCompanies', params);
    };

    const startInReview = (companyId) => {
      dispatch('updateCompany', {
        form: {status: 'in_review'},
        id: companyId
      });
    };

    const filtersState = reactive({
      country: undefined,
      type: undefined,
      status: undefined,
      name: undefined,
      productCategoryIds: undefined,
      countriesCoverage: undefined
    });

    const filtersChanged = ref(false);
    watch(
      () => props.country,
      () => {
        filtersState.country = props.country;
        handleTableFilter();
      }
    );
    watch(
      () => props.status,
      () => {
        filtersState.status = props.status;
        handleTableFilter();
      }
    );
    watch(
      () => props.productCategoryIds,
      () => {
        filtersState.productCategoryIds = props.productCategoryIds;
        handleTableFilter();
      }
    );
    watch(
      () => props.countriesCoverage,
      () => {
        filtersState.countriesCoverage = props.countriesCoverage;
        handleTableFilter();
      }
    );
    watch(
      () => props.search,
      () => {
        filtersState.name = props.search;
        if (props.search.length === 0 || props.search.length >= 3) {
          setTimeout(() => {
            handleTableFilter();
          }, 100);
        }
      }
    );

    const handleTableFilter = () => {
      filtersChanged.value = true;
      handleTableChange(pagination, filtersState, {});
      context.emit('searched');
    };

    const {
      data: dataSource, run, mutate, loading, current, pageSize
    } = usePagination(getCompanies, {
      formatResult: () => getters.companies,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'perPage',
        totalKey: 'totalEntries'
      }
    });
    const pagination = computed(() => ({
      total: getters.companies?.totalEntries,
      current: current.value,
      pageSize: pageSize.value
    }));

    const handleTableChange = (pag, filters, sorter) => {
      const filterParams = {};
      _.forEach(filtersState, (field, key) => {
        filtersState[key] = filters[key] || field;
        if (filtersState[key] === undefined || filtersState[key] === '') return;
        if (key === 'productCategoryIds') {
          filterParams[_.snakeCase(key)] = [parseInt(filtersState[key])];
        } else {
          filterParams[_.snakeCase(key)] = filtersState[key];
        }
      });
      run({
        per_page: pag.pageSize,
        page: filtersChanged.value ? 1 : pag.current,
        ...filterParams
      });
      filtersChanged.value = false;
    };

    watch(
      () => route.name,
      () => {
        if (route.name !== 'BackOfficeClientCompanies' && route.name !== 'BackOfficeAgentCompanies') {
          return;
        }
        if (companyType.value === 'Agent') {
          columns.value.splice(3, 0, {
            title: t('models.company.attributes.numberOfServiceCountries'),
            slots: {customRender: 'numberOfServiceCountries'}
          });
        } else {
          columns.value.splice(3, 1);
        }
        filtersState.type = companyType.value;
        filtersState.country = undefined;
        filtersState.status = undefined;
        filtersState.productCategoryIds = undefined;
        filtersState.countriesCoverage = undefined;
        filtersState.name = undefined;
        handleTableFilter();
      }
    );

    const companyProfile = (record) => {
      return {
        name: 'CompanyProfile',
        params: {companyId: record._jv.id}
      };
    };

    const reject = async ({id, index}) => {
      loading.value = true;
      await dispatch('rejectCompany', {id, index});
      loading.value = false;
      mutate(getters.companies);
    };

    const blacklist = async ({
      id, index, event
    }) => {
      loading.value = true;
      await dispatch('blacklistCompany', {
        id,
        index,
        message: event
      });
      loading.value = false;
      mutate(getters.companies);
    };

    return {
      columns,
      dataSource,
      pagination,
      loading,
      moment,
      handleTableChange,
      companyProfile,
      reject,
      blacklist,
      companyStatusColors,
      companyServiceTypesColors,
      startInReview,
      companyType
    };
  }
});
</script>

<style lang="scss" scoped>
.table-actions {
  text-align: left !important;
}
</style>
