<template>
  <div>
    <app-header :routes="routes()" />
    <Main>
      <a-row :gutter="15">
        <a-col :xs="24" class="table-search">
          <a-row :gutter="[10,0]" type="flex" justify="start">
            <a-col :xs="24" :sm="12" :md="6" :xl="5">
              <select-field
                classes="hide-input-label"
                v-model:fieldValue="country"
                :options="countries"
                placeholder="models.company.attributes.country"
                :enableSearch="true"
                allow-clear
              />
            </a-col>
            <a-col :xs="24" :sm="12" :md="6" :xl="5">
              <select-field
                classes="hide-input-label"
                v-model:fieldValue="status"
                :options="statusFilterOptions"
                placeholder="models.company.attributes.status"
                allow-clear
              />
            </a-col>
            <a-col :xs="24" :sm="12" :md="6" :xl="5" v-if="type === 'Agent'">
              <select-field
                classes="hide-input-label"
                v-model:fieldValue="productCategoryIds"
                :options="productCategories"
                placeholder="models.company.attributes.productCategories"
                :enableSearch="true"
                allow-clear
              />
            </a-col>
            <a-col :xs="24" :sm="12" :md="6" :xl="5" v-if="type === 'Agent'">
              <select-field
                classes="hide-input-label"
                v-model:fieldValue="countriesCoverage"
                :options="countries"
                placeholder="models.company.attributes.countriesCoverage"
                :enableSearch="true"
                allow-clear
              />
            </a-col>
            <a-col :xs="24" :sm="12" :md="6" :xl="4">
              <a-input-search v-model:value="search" :placeholder="$t('actions.search')" class="custom-search" />
            </a-col>
          </a-row>
        </a-col>
        <a-col :xxl="24" :xl="24" :md="24" :xs="24">
          <sd-cards>
            <companies-table :country="country" :status="status"
            :productCategoryIds="productCategoryIds" :countriesCoverage="countriesCoverage" :search="search"/>
          </sd-cards>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref, watch
} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import CompaniesTable from '@/components/companies/Table';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import SelectField from '@/components/shared/fields/Select';
import {useRoute} from 'vue-router';

export default defineComponent({
  name: 'Companies',
  components: {
    Main,
    CompaniesTable,
    SelectField
  },
  setup() {
    const {t} = useI18n();
    const {dispatch, getters} = useStore();
    const country = ref('');
    const status = ref('');
    const productCategoryIds = ref('');
    const countriesCoverage = ref('');
    const search = ref('');

    dispatch('getCountries');
    dispatch('getList', 'PRODUCT_CATEGORIES');

    const route = useRoute();
    const type = computed(() => route.name === 'BackOfficeClientCompanies' ? 'Client' : 'Agent');


    const routes = () => [{
      name: type.value === 'Client' ? 'BackOfficeClientCompanies' : 'BackOfficeAgentCompanies',
      breadcrumbName: type.value === 'Client' ? 'layout.companies.client' : 'layout.companies.agent'
    }];


    watch(() => route.name, () => {
      country.value = undefined;
      status.value = undefined;
      search.value = undefined;
      productCategoryIds.value = undefined;
      countriesCoverage.value = undefined;
    }, {immediate: true});

    return {
      country,
      status,
      search,
      productCategoryIds,
      countriesCoverage,
      routes,
      type,
      countries: computed(() => getters.countries),
      productCategories: computed(() => getters.productCategories),
      typeFilterOptions: [
        {value: 'Client', label: t('models.company.type.client')},
        {value: 'Agent', label: t('models.company.type.agent')}
      ],
      statusFilterOptions: [
        {value: 'cs_verification_required', label: t('models.company.status.cs_verification_required')},
        {value: 'blacklisted', label: t('models.company.status.blacklisted')},
        {value: 'approved', label: t('models.company.status.approved')},
        {value: 'rejected', label: t('models.company.status.rejected')},
        {value: 'in_review', label: t('models.company.status.in_review')}
      ]
    };
  }
});
</script>

<style>
.custom-search {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 48px !important;
  padding: 0 11px;
}
.custom-search>.ant-input {
  border-radius: 25px !important;
}
</style>
